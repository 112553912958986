/**
 * Decorator that checks if LocalStorage is accessible.
 * @param {*} _ - Placeholder parameter for decorators, not used.
 * @param {*} __ - Placeholder parameter for decorators, not used.
 * @param {PropertyDescriptor} descriptor - The PropertyDescriptor of the method being decorated.
 * @returns {PropertyDescriptor} - The modified PropertyDescriptor.
 */
function CheckLocalStorageAccess(_, __, descriptor)
{

  const originalMethod = descriptor.value;
  // eslint-disable-next-line func-names
  descriptor.value = function (...args)
  {

    try
    {

      // Try accessing local storage to see if it's available
      localStorage.getItem('test');
      // If local storage is accessible, call the original method
      return originalMethod.apply(this, args);

    }
    catch (_)
    {

      // If an error is thrown, local storage is not available, so warn the user and return null
      console.warn('Local storage is not available');
      return null;

    }

  };
  return descriptor;

}

/**
 * Class for managing data in LocalStorage.
 * All methods in this class use the CheckLocalStorageAccess decorator to ensure LocalStorage is accessible before performing operations.
 * @exports DPLocalStorage
 */
export class DPLocalStorage
{

  /**
   * Sets a value in LocalStorage.
   * @param {string} key - The key to store the value under.
   * @param {any} value - The value to store.
   * @decorator CheckLocalStorageAccess
   */
  @CheckLocalStorageAccess
  static set(key, value)
  {

    localStorage.setItem(key, JSON.stringify(value));

  }

  /**
   * Retrieves a value from LocalStorage.
   * @param {string} key - The key of the value to retrieve.
   * @returns {T} - The retrieved value.
   * @decorator CheckLocalStorageAccess
   */
  @CheckLocalStorageAccess
  static get(key)
  {

    return JSON.parse(localStorage.getItem(key) || '');

  }

  /**
   * Removes a value from LocalStorage.
   * @param {string} key - The key of the value to remove.
   * @decorator CheckLocalStorageAccess
   */
  @CheckLocalStorageAccess
  static remove(key)
  {

    localStorage.removeItem(key);

  }

}
